import React, { Component, Fragment } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Config from '../../data/SiteConfig';
import Modal from "../components/Modal";

export default class OrderPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet title={[this.props.data.markdownRemark.frontmatter.title, Config.siteTitle].join(' | ')} />

        <Modal pageOrder />
      </Fragment>
    );
  }
}

export const pageQuery = graphql`
    query OrderPage {
        markdownRemark(fields:{slug: {eq: "/"}}) {
            html

            frontmatter {
                title
            }

            fields {
                slug
                post
            }
        }
    }
`;
